import App from 'next/app';
import { hydrate } from 'react-emotion';

// commented because it cause web from yarn dev and yarn build to have different ccss
// if somehow cannot navigate between page please uncomment this
import '../public/static/empty.css';
import '@traveloka/ckeditor5-custom-txt-build/dist/ckeditor.css';

if (typeof window !== 'undefined') {
  hydrate(window.__NEXT_DATA__.ids);
}

export default App;
